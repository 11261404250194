* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

.partner-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.partner-main {
    display: flex;
    flex: 1;
    padding: 20px;
}

.filters {
    width: 250px;
    margin-right: 20px;
    padding: 10px;
    background: #eeeeee;
    border-radius: 8px;
}

.filters h2 {
    margin-bottom: 10px;
}

.filters label {
    display: block;
    margin: 10px 0 5px;
}

.filters select, .filters input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.results {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.result-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
}

.image-container {
    flex: 0 0 150px;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.info-container {
    padding: 8px;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.info-container h3 {
    font-size: 20px;
    margin-bottom: 5px;
}

.info-container p {
    margin-bottom: 2px;
    color: #555;
}

.service-icons {
    display: flex;
    align-items: center;
    gap: 15px;
    color: #888;
}

.service-icons span {
    display: flex;
    align-items: center;
    gap: 5px;
}

.service-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    color: #888;
}

.service-details span {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: 5px;
}

.price-button {
    flex-direction: column;
    align-items: flex-end;
}

.price-button button {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
}

footer {
    background-color: #f8f9fa;
    padding: 10px 20px;
    text-align: center;
}
