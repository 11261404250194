/* Estilos do componente */
.App {
  text-align: center;
  font-family: 'Arial', sans-serif;
  color: #3a2e2a;
  overflow-x: hidden;
}

.video-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

#video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.App-header {
  position: relative;
  width: 100%;
  z-index: 1;
}

.App-header.reduced-header {
  height: 100px;
}

.App-logo {
  height: 300px;
  width: 300px;
}

.App-header.reduced-header .App-logo {
  height: 80px;
  width: 80px;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 350px;
  width: 40%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
}

.text {
  font-size: 42pt;
  font-weight: bold;
  margin-bottom: 100px;
  margin-bottom: 40px; /* Aumentar o espaço entre o texto e as combos */
}

.info-text {
  font-size: 14pt;
  margin-bottom: 20px; /* Espaço entre o texto informativo e as combos */
}

.dropdowns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px; /* Espaço entre as combos e o botão */
}

.state-dropdown {
  padding: 10px;
  font-size: 18pt;
  border-radius: 5px;
  border: 2px solid #ccc;
  width: 30%;
  height: 65px;
  opacity: 0.7;
}

.city-dropdown {
  padding: 10px;
  font-size: 18pt;
  border-radius: 5px;
  border: 2px solid #ccc;
  width: 70%;
  height: 65px;
  opacity: 0.7;
}

.service-button {
  padding: 10px 20px;
  font-size: 14pt;
  border-radius: 5px;
  background-color: #01c601;
  color: white;
  border: none;
  cursor: pointer;
  align-self: flex-end; /* Alinhar à direita */
  margin-left: auto; /* Margem automática à esquerda para empurrar o botão para a direita */
}

.service-button {
  float: right;
}

.service-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.service-button:hover:enabled {
  background-color: #019801;
}

.main-nav {
  position: fixed;
  top: 0;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  z-index: 10;
}

.main-nav.solid-nav {
  background: #3a2e2a; /* Fundo marrom quando não está na página inicial */
}

.main-nav.scrolled {
  background: #3a2e2a;
  transition: background 0.3s ease;
}

.logo-container {
  display: flex;
  align-items: center;
}

.nav-logo {
  height: 45px;
  width: 120px;
}

.nav-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex: 1;
  justify-content: center;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links li a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.nav-links li a .menu-icon {
  margin-right: 10px;
}

.nav-links li a:hover {
  color: #878686;
}

.nav-buttons {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.nav-button .menu-icon {
  margin-right: 10px;
}

.nav-button {
  text-decoration: none;
  font-weight: bold;
  margin-right: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 5px;
  background: none;
  color: white;
  cursor: pointer;
  padding: 7px 20px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-button:hover {
  background: #fff !important;
  color: rgb(31, 30, 30);
}

.special-button {
  background: #f1e4d4;
  color: #3a2e2a;
  border: none;
  margin-left: 5px;
  padding: 8px 10px;
}

.special-button:hover {
  background: #01c601 !important;
}

.extra-content {
  padding: 20px;
  background-color: #f9f9f9;
}

.menu-toggle {
  display: none;
  cursor: pointer;
}

.menu-toggle .menu-icon {
  font-size: 24px;
  color: #fff;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    margin-left: 10px;
    margin-top: 7px;
  }

  .nav-links {
    flex-direction: column;
    align-items: flex-start;
    background: #3a2e2a;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: none;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .nav-buttons {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    background: #3a2e2a;
    position: absolute;
    top: calc(100% + 200px);
    left: 0;
    width: 100%;
    display: none;
  }

  .nav-buttons.open {
    display: flex;
    top: calc(100% + 175px);
  }

  .nav-button {
    width: 100%;
    margin: 5px 0;
  }

  .special-button {
    width: 100%;
  }
}

.nav-user {
  color: white;
  font-weight: bold;
  padding: 15px;
}
