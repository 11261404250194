.login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.login-container {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 450px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.login-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.inline-inputs {
  display: flex;
  justify-content: space-between;
}

.inline-inputs label {
  flex: 1;
  margin-right: 10px;
}

.inline-inputs label:last-child {
  margin-right: 0;
}

.login-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  color: #333;
  position: relative;
}

.login-form input::placeholder {
  color: #aaa;
}

.login-form input,
.login-form select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.login-form button {
  padding: 10px;
  font-size: 16px;
  background-color: #f1e4d4;
  color: #3a2e2a;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #f1e4d4;
}

.sso-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sso-button {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sso-button.google {
  background-color: #db4437;
  color: #fff;
}

.sso-button.google:hover {
  background-color: #c33d2e;
}

.sso-button.microsoft {
  background-color: #0078d4;
  color: #fff;
}

.sso-button.microsoft:hover {
  background-color: #005a9e;
}

.sso-button.apple {
  background-color: #000;
  color: #fff;
}

.sso-button.apple:hover {
  background-color: #333;
}

.login-container p {
  margin-top: 10px;
  color: #333;
}

.link-button {
  background: none;
  border: none;
  color: #0078d4;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-size: 1em;
}

.link-button:hover {
  color: #005a9e;
}

.error-message {
  color: red !important;
  margin: 10px;
  font-size: 0.9em;
}

.success-message {
  color: green !important;
  margin-top: 10px;
  font-size: 0.9em;
}

.nav-username {
  color: white;
  font-weight: bold;
  padding: 15px;
}

