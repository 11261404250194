/* Company.css */

.company-container {
  text-align: center;
  background-color: #fff;
}

.company-header {
  background-color: #f1e4d4;
  padding: 50px 20px;
  margin-bottom: 50px;
}

.company-header h2 {
  font-size: 36px;
  color: #3a2e2a;
  margin-bottom: 20px;
}

.company-header p {
  font-size: 18px;
  color: #3a2e2a;
}

.video-background {
  position: relative;
  height: 400px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px;
}

.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.company-stats {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 100px;
  width: 100%;
  position: relative;
  z-index: 0;
  color: #fff;
}

.stat-card {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 200px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.stat-card h3 {
  font-size: 24px;
  color: #3a2e2a;
  margin-bottom: 10px;
}

.stat-card p {
  font-size: 16px;
  color: #3a2e2a;
}

.company-content {
  text-align: left;
  margin: 0 auto;
  max-width: 800px;
}

.company-content h3 {
  font-size: 28px;
  color: #3a2e2a;
  margin-bottom: 20px;
}

.company-content p {
  font-size: 18px;
  color: #3a2e2a;
  line-height: 1.6;
  margin-bottom: 20px;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.contact-section {
  text-align: center;
  margin-top: 40px;
}

.contact-section h3 {
  font-size: 24px;
  color: #3a2e2a;
  margin-bottom: 10px;
}

.contact-section p {
  font-size: 18px;
  color: #3a2e2a;
}

/* Adicional de estilo para navegação fixa */
.main-nav.scrolled {
  background: #3a2e2a !important;
  transition: background 0.3s ease;
}

.nav-links li a:hover {
  color: #f1e4d4 !important;
}

.nav-button:hover {
  background: #f1e4d4 !important;
  color: #3a2e2a !important;
}

.special-button:hover {
  background: #f1e4d4 !important;
}
