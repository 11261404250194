.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* garante que o overlay esteja acima de todos os outros elementos */
  }
  
  .loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* cor da borda */
    border-top: 4px solid #ffffff; /* cor da borda superior */
    border-radius: 50%; /* formato do carregamento */
    width: 50px; /* largura do carregamento */
    height: 50px; /* altura do carregamento */
    animation: spin 1s linear infinite; /* movimento do carregamento */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  